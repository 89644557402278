<template>
  <div
    class="p-3 border-[1px] rounded-lg flex flex-col self-baseline border-neutral-300 dark:border-neutral-800 w-full"
  >
    <div
      v-if="!props.isArticle"
      class="py-1 mb-2 text-center text-neutral-500 bg-neutral-200 dark:bg-neutral-800 rounded-md font-bold"
    >
      {{ LL.about_us() }}
    </div>
    <UNavigationMenu orientation="vertical" :items="links" />
  </div>
</template>

<script setup lang="ts">
import { typesafeI18n } from "~/locales/i18n-vue";

const { LL } = typesafeI18n();

const props = defineProps({
  isArticle: {
    type: Boolean,
    default: false,
  },
});

interface SocialLink {
  id: string;
  label: string;
  icon: string;
  to: string;
  target?: string;
  badge: string;
}

const createSocialLink = (
  id: string,
  label: string,
  icon: string,
  url: string,
  badge: string,
  target = "_blank"
): SocialLink => ({
  id,
  label,
  icon,
  to: url,
  target,
  badge,
});

const socialLinks = {
  youtube: computed(() => [
    createSocialLink(
      "youtube",
      "TechMely",
      "i-simple-icons-youtube",
      "https://www.youtube.com/c/TechMely?sub_confirmation=1",
      `5K ${LL.value.follow()}`
    ),
  ]),

  discord: computed(() =>
    props.isArticle
      ? []
      : [
          createSocialLink(
            "discord",
            "Discord",
            "i-simple-icons-discord", 
            "https://discord.gg/PGERaWD4CQ",
            `2K ${LL.value.members()}`
          ),
        ]
  ),

  github: computed(() => [
    createSocialLink(
      "github",
      "TechMely",
      "i-simple-icons-github",
      "https://github.com/techmely",
      `51 ${LL.value.follow()}`
    ),
  ]),

  facebook: computed(() => [
    createSocialLink(
      "techmely-fanpage",
      "TechMely Fanpage", 
      "i-simple-icons-facebook",
      "https://www.facebook.com/techmely",
      "5k"
    ),
    createSocialLink(
      "timvietfe-group",
      "Group tìm việc FrontEnd",
      "i-simple-icons-angular", 
      "https://www.facebook.com/groups/timviecfe/",
      "8.1k"
    ),
    createSocialLink(
      "congdongfe-group",
      "Tìm việc Backend, Devops",
      "i-simple-icons-nodedotjs",
      "https://www.facebook.com/groups/timviecbackend",
      "5k"
    ),
  ]),

  tiktok: computed(() => [
    createSocialLink(
      "tiktok",
      "TechMely",
      "i-simple-icons-tiktok",
      "https://www.tiktok.com/@techmely",
      `6.5k ${LL.value.follow()}`
    ),
  ]),
};

const links = computed(() => [
  ...socialLinks.youtube.value,
  ...socialLinks.tiktok.value,
  ...socialLinks.discord.value,
  ...socialLinks.github.value,
  ...socialLinks.facebook.value,
]);
</script>
